import * as React from "react";
// import PropTypes from "prop-types";
// import { Link } from "gatsby";

import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';

import { graphql, navigate, Link } from 'gatsby';
import { connect } from 'react-redux';
import Helper from '@justnice/helper';

import * as MUIIcons from '@material-ui/icons';
import Icon from '../Icon';
import moment from 'moment';


const menuTextWidth = 150;

const MenuMain = (props) => {
  const locale = props.ui.get('lang');
  const now = new Date();
  const containerRef = React.useRef(null);
  const [expandedItems, setExpandedItems] = React.useState([]);
  const appMenu = Helper.getStateAsJSObj(props.system, 'menu.data', []);
  const rootItems = appMenu.filter(item => !Helper.isNotNullAndUndefined(item.parent) &&
                      (!Helper.isNotNullAndUndefined(item.start_on) || now.getTime() >= moment(item.start_on).toDate().getTime()) &&
                      (!Helper.isNotNullAndUndefined(item.end_on) || now.getTime() < moment(item.end_on).toDate().getTime())
                    )
                    .sort((a, b) => (a.sort > b.sort) ? 1 : -1);

  const containerOnClick = (itemId) => {
    var newExpandedItems = expandedItems.slice();
    const index = newExpandedItems.indexOf(itemId);
    
    if (index > -1) {
      newExpandedItems.splice(index, 1);
    }
    else {
      newExpandedItems.push(itemId);
    }
    
    setExpandedItems(newExpandedItems);
  };

  const isParentExpanded = (parentId) => {
    let expanded = false;
    const parentItem = appMenu.find(item => item.id === parentId);

    if(Helper.isNotNullAndUndefined(parentItem)) {
      // Check the current parent item to see if it is expanded?
      expanded = expandedItems.indexOf(parentId) >= 0;

      // If expanded, check the current parent have parent item or not, if have, recursively check
      // if the parent's parent is expanded or not
      if(expanded && Helper.isNotNullAndUndefined(parentItem.parent)) {
        expanded = isParentExpanded(parentItem.parent.id);
      }
    }

    return expanded;
  };

  const menuItemOnClick = (item) => {
    if(item && !item.is_container && Helper.stringHasValue(item.route)) {
      if(locale === 'en')
        navigate(item.route);
      else
        navigate(`/${locale}${item.route}`);
    }
  }


  const populateChildMenu = (parentId, level) => { 
    const childItems = appMenu.filter(item => item.parent?.id === parentId &&
                        (!Helper.isNotNullAndUndefined(item.start_on) || now.getTime() >= moment(item.start_on).toDate().getTime()) &&
                        (!Helper.isNotNullAndUndefined(item.end_on) || now.getTime() < moment(item.end_on).toDate().getTime())
                       )
                       .sort((a, b) => (a.sort > b.sort) ? 1 : -1);

    const expanded = isParentExpanded(parentId); 
    
    return(
      childItems.map((item) => {
        if(!item.is_container) {
          return (<Collapse in={expanded} key={'Collapse' + item.id}>
            <ListItem button key={item.id} style={{marginLeft: level * 20}} onClick={() => menuItemOnClick(item)}>
              <ListItemIcon style={{minWidth: 35}}><Icon source={item.icon} /></ListItemIcon>
              <ListItemText style={{maxWidth: menuTextWidth - (level * 20)}} primary={item.name} />
            </ListItem>
          </Collapse>);
        }
        else {
          let menuItemWithChild = [];

          menuItemWithChild.push(
            <Collapse in={expanded} key={'Collapse' + item.id}>
              <ListItem button key={item.id} style={{marginLeft: level * 20}}
                onClick={() => containerOnClick(item.id)}>
                <ListItemIcon style={{minWidth: 35}}><Icon source={item.icon} /></ListItemIcon>
                <ListItemText style={{maxWidth: menuTextWidth - (level * 20)}} primary={item.name} />
                <ListItemIcon style={{minWidth: 30}}>{expandedItems.includes(item.id) ? <MUIIcons.ExpandMore /> : <MUIIcons.ChevronRight />}</ListItemIcon>
              </ListItem>
            </Collapse>
          );

          menuItemWithChild = menuItemWithChild.concat(populateChildMenu(item.id, level + 1));
          return menuItemWithChild;
        }
      })
    );
  };

  let menuItems = rootItems.map((item, index) => {
    if(!item.is_container) {
      return (
        <ListItem button key={item.id} onClick={() => menuItemOnClick(item)}>
          <ListItemIcon style={{minWidth: 35}}><Icon source={item.icon} /></ListItemIcon>
          <ListItemText style={{maxWidth: menuTextWidth}} primary={item.name} />
        </ListItem>
      );
    }
    else {
      let menuItemWithChild = [];

      menuItemWithChild.push(
        <ListItem button key={item.id} onClick={() => containerOnClick(item.id)}>
          <ListItemIcon style={{minWidth: 35}}><Icon source={item.icon} /></ListItemIcon>
          <ListItemText style={{maxWidth: menuTextWidth}} primary={item.name} />
          <ListItemIcon style={{minWidth: 30}}>{expandedItems.includes(item.id) ? <MUIIcons.ExpandMore /> : <MUIIcons.ChevronRight />}</ListItemIcon>
        </ListItem>
      );

      menuItemWithChild = menuItemWithChild.concat(populateChildMenu(item.id, 1));
      return menuItemWithChild;
    }
  });

  
  return(<List key={'MainMenu'} ref={containerRef}>{menuItems}</List>);
}

// MenuMain.propTypes = {
//   siteTitle: PropTypes.string,
// }

// MenuMain.defaultProps = {
//   siteTitle: ``,
// }


export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    system: state['system'],
  };
})(MenuMain);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;