import * as React from "react";
import PropTypes from "prop-types";
// import { Link } from "gatsby"；

import clsx from 'clsx';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { AppBar, Avatar, Toolbar, Drawer, Typography, 
  Button, IconButton, Divider, 
  ListItemIcon, Collapse,
  // List, ListItem, ListItemText,
  CssBaseline, Menu, MenuItem, Fade,
  Badge
} from '@material-ui/core';

import {useTranslation} from 'gatsby-plugin-react-i18next';
import { graphql, navigate } from 'gatsby';
import { connect } from 'react-redux';
import Helper from '@justnice/helper';
import Api from '../../lib/api';

import * as MUIIcons from '@material-ui/icons';
//import { purple } from '@material-ui/core/colors';
import MenuMain from '../MenuMain';
import useStyles from '../../assets/jss/components/headerStyle';
import * as uiActions from '../../redux/actions/uiActions';
import * as systemActions from '../../redux/actions/systemActions';
import * as sessionActions from '../../redux/actions/sessionActions';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const Header = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [langOpen, setLangOpen] = React.useState(false);
  const isLogin = Helper.getStateAsJSObj(props.session, 'auth.data.user', null) !== null;
  const myProfile = Helper.getStateAsJSObj(props.session, 'myProfile.data', null);
  const cartItems = Helper.getStateAsJSObj(props.cart, 'cart.data', []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfileMenu = Boolean(anchorEl);
  const locale = props.ui.get('lang');

  // console.log('Header MyProfile: ', myProfile);
  var myAvatar = Helper.carefullyGetValue(myProfile, 'bio_info.photo_id.formats.thumbnail.url'.split('.'), undefined);

  if(!Helper.isNotNullAndUndefined(myAvatar)) {
    myAvatar = Helper.carefullyGetValue(myProfile, 'bio_info.photo_id.formats.small.url'.split('.'), undefined);
  }

  if(!Helper.isNotNullAndUndefined(myAvatar)) {
    myAvatar = Helper.carefullyGetValue(myProfile, 'bio_info.photo_id.url'.split('.'), undefined);
  }

  // console.log('Header myAvatar: ', myAvatar);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAvatarOnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarOnClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOnClick = (event) => {
    //setAnchorEl(null);
    // console.log('Profile menu item clicked: ', event);

    switch(event.toUpperCase()) {
      case "LANGUAGE":
        setLangOpen(!langOpen);
        break;

      case "PROFILE":
        navigate('/profile')
        break;

      case "LOGOUT": 
        props.dispatch(
          sessionActions.logout()
        );
        //navigate('/', {replace: true});
        // Use window.location.href instead of navigate to force a page "reload"
        // so that the app menu and cart state will be reloaded
        setTimeout(() => {
          window.location.href = `${process.env.AUTH0_LOGOUT_URL}${process.env.AUTH0_LOGOUT_REDIRECT_URL}`;
        }, 500);
        
        break;
    }
  };

  const setLanguage = (lang) => {
    setAnchorEl(null);
    setLangOpen(false);

    if(lang !== locale) {
      props.dispatch(
        uiActions.setLanguage(lang)
      );

      props.dispatch(
        systemActions.appMenuGetList(lang)
      );
    }
  }

  const getFullName = (name) => {
    var fullName = '';

    if(Helper.isNotNullAndUndefined(name)) {
      if(Helper.isNotNullAndUndefined(name.first_name)) {
        fullName = name.first_name;
      }

      if(Helper.isNotNullAndUndefined(name.last_name)) {
        fullName += ' ' + name.last_name;
      }
    }
    
    return fullName;
  };

  const getInitial = (name) => {
    var initial = '';

    if(Helper.isNotNullAndUndefined(name)) {
      if(Helper.isNotNullAndUndefined(name.first_name)) {
        let nameArr = name.first_name.split(' ');
        for(let idx=0; idx<nameArr.length; idx++) {
          if(nameArr[idx].trim().length > 0) {
            initial += nameArr[idx].trim()[0].toUpperCase();
          }

          if(initial.length >= 2) {
            break;
          }
        }
      }

      if(initial.length < 2 && Helper.isNotNullAndUndefined(name.last_name)) {
        let nameArr = name.last_name.split(' ');
        for(let idx=0; idx<nameArr.length; idx++) {
          if(nameArr[idx].trim().length > 0) {
            initial += nameArr[idx].trim()[0].toUpperCase();
          }

          if(initial.length >= 2) {
            break;
          }
        }
      }
    }
    
    return initial;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="inherit"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MUIIcons.Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            {props.siteTitle}
          </Typography>
          
          <div className={classes.avatarContainer}>
            {
              Helper.arrayHasItem(cartItems) &&
              <IconButton aria-label="cart" size="medium" style={{marginRight: 10}}
                onClick={() => navigate('/cart')}
              >
                <StyledBadge badgeContent={cartItems.length} color="primary">
                  <MUIIcons.ShoppingCart />
                </StyledBadge>
              </IconButton>
            }
            <IconButton onClick={handleAvatarOnClick} size="small">
              {
                isLogin ?
                <Avatar alt={getFullName(myProfile?.name)} 
                  src={Helper.isNotNullAndUndefined(myAvatar) ? process.env.GATSBY_API_URL + myAvatar : undefined}
                >{getInitial(myProfile?.name)}</Avatar>
                :
                <MUIIcons.Language style={{width: 25, height: 25}} />
              }
            </IconButton>
            {
              !isLogin &&
              <Button className={classes.loginButton}
                variant="text"
                onClick={() => navigate(Api.authProviderLoginUrl('auth0'))}
              >{t('buttons.login')}</Button>
            }
          </div>
          <Menu
            anchorEl={anchorEl}
            open={openProfileMenu}
            onClose={handleAvatarOnClose}
            TransitionComponent={Fade}
            style={{marginTop: 40}}
          >
            {
              isLogin &&
              <MenuItem onClick={() => handleMenuOnClick('PROFILE')}>
                <ListItemIcon className={clsx(classes.profileMenuIcon)}>
                  <Avatar style={{width: 25, height: 25}} />
                </ListItemIcon>
                {t('menu.profile.item.profile')}
              </MenuItem>
            }
            <MenuItem onClick={() => handleMenuOnClick('LANGUAGE')}>
              <ListItemIcon className={clsx(classes.profileMenuIcon)}>
                <MUIIcons.Language style={{width: 25, height: 25}} />
              </ListItemIcon>
              {t('menu.profile.item.locale')}
              <ListItemIcon className={clsx(classes.profileMenuIcon)}>
                {langOpen ? <MUIIcons.ExpandMore /> : <MUIIcons.ChevronRight />}
              </ListItemIcon>
            </MenuItem>
            <Collapse in={langOpen} style={{width: 150, paddingLeft: 40}}>
              <MenuItem onClick={() => setLanguage('en')}>
                {t('menu.profile.item.locale.en')}
                {
                  locale === 'en' &&
                  <ListItemIcon className={clsx(classes.profileMenuIcon)}>
                    <MUIIcons.Check style={{width: 25, height: 25}} />
                  </ListItemIcon>
                }
              </MenuItem>
              <MenuItem onClick={() => setLanguage('zh-Hans')}>
                {t('menu.profile.item.locale.zhhans')}
                {
                  locale === 'zh-Hans' &&
                  <ListItemIcon className={clsx(classes.profileMenuIcon)}>
                    <MUIIcons.Check style={{width: 25, height: 25}} />
                  </ListItemIcon>
                }
              </MenuItem>
              <MenuItem onClick={() => setLanguage('zh-Hant')}>
                {t('menu.profile.item.locale.zhhant')}
                {
                  locale === 'zh-Hant' &&
                  <ListItemIcon className={clsx(classes.profileMenuIcon)}>
                    <MUIIcons.Check style={{width: 25, height: 25}} />
                  </ListItemIcon>
                }
              </MenuItem>
            </Collapse>
            
            {
              isLogin &&
              <Divider />
            }
            {/* {
              isLogin &&
              <MenuItem onClick={() => handleMenuOnClick('SETTING')}>
                <ListItemIcon className={clsx(classes.profileMenuIcon)}>
                  <MUIIcons.Settings fontSize="small" />
                </ListItemIcon>
                {t('menu.profile.item.setting')}
              </MenuItem>
            } */}
            {
              isLogin &&
              <MenuItem onClick={() => handleMenuOnClick('LOGOUT')}>
                <ListItemIcon className={clsx(classes.profileMenuIcon)}>
                  <MUIIcons.ExitToApp fontSize="small" />
                </ListItemIcon>
                {t('menu.profile.item.logout')}
              </MenuItem>
            }
          </Menu>

          
          
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <MUIIcons.ChevronLeft /> : <MUIIcons.ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <MenuMain />
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <a target='_blank' href="https://www.digitalocean.com/?refcode=123a6df76c97&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge">
            <img style={{maxWidth: 170}} src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%203.svg" alt="DigitalOcean Referral Badge" />
          </a>
        </div>
      </Drawer>
    </div>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}


export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    system: state['system'],
    cart: state['cart']
  };
})(Header);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;