import {actionType} from './actionType';
import Api from '../../lib/api';
import Helper from '@justnice/helper';

export function cartGetList(callback) {
  return function(dispatch) {
    dispatch({type: actionType.cart.cartGetListPending, payload: null});
    
    Api.cartListGet()
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.cart.cartGetListFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.cart.cartGetListRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.cart.cartGetListRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function cartItemAdd(item, callback) {
  return function(dispatch) {
    dispatch({type: actionType.cart.cartItemAddPending, payload: null});
    
    Api.cartItemAdd(item)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.cart.cartItemAddFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.cart.cartItemAddRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.cart.cartItemAddRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function cartItemRemove(itemId, callback) {
  return function(dispatch) {
    dispatch({type: actionType.cart.cartItemRemovePending, payload: null});
    
    Api.cartItemRemove(itemId)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.cart.cartItemRemoveFulfilled,
          payload: itemId
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.cart.cartItemRemoveRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.cart.cartItemRemoveRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function cartItemRemoveAll(callback) {
  return function(dispatch) {
    dispatch({type: actionType.cart.cartItemRemoveAllPending, payload: null});
    
    Api.cartItemRemoveAll()
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.cart.cartItemRemoveAllFulfilled,
          payload: null
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.cart.cartItemRemoveAllRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.cart.cartItemRemoveAllRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function cartSubmitPayment(paymentInfo, callback) {
  return function(dispatch) {
    dispatch({type: actionType.cart.cartSubmitPaymentPending, payload: null});
    
    Api.paymentNew(paymentInfo)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.cart.cartSubmitPaymentFulfilled,
          payload: result.data
        });
        callback && callback(true, result.data);
      }
      else {
        dispatch({
          type: actionType.cart.cartSubmitPaymentRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.cart.cartSubmitPaymentRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}