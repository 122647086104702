/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate, Link } from "gatsby"
import { 
  CssBaseline, useMediaQuery, Paper, Button, Backdrop, CircularProgress
} from '@material-ui/core';
import { createTheme, ThemeProvider, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import Header from "../Header";
import { connect } from 'react-redux';
import {useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import useWindowResize from "../../components/Hooks/useWindowResize";
import Seo from "../../components/Seo";
import Helper from '@justnice/helper';

import * as systemActions from '../../redux/actions/systemActions';
import * as cartActions from '../../redux/actions/cartActions';
import * as sessionActions from '../../redux/actions/sessionActions';
import useCommonStyles from '../../assets/jss/commonStyles';
import Api from '../../lib/api';
import jwt_decode from "jwt-decode";

//import "./style.css"

const Layout = (props) => {
  const {changeLanguage} = useI18next();
  const {t} = useTranslation();
  const windowSize = useWindowResize();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const auth = Helper.getStateAsJSObj(props['session'], 'auth.data', null);
  const menuItems = Helper.getStateAsJSObj(props['system'], 'menu.data', []);
  const metaData = Helper.isNotNullAndUndefined(props.meta) ? props.meta : [];
  const isAuth = Helper.isNotNullAndUndefined(auth) && Helper.stringHasValue(auth.jwt) && Helper.isNotNullAndUndefined(auth.user);
  const now = new Date();
  var authExpired = true;
  
  if(isAuth) {
    var decoded = jwt_decode(auth.jwt);

    if(Helper.isNotNullAndUndefined(decoded?.exp)) {
      authExpired = (decoded.exp * 1000) < now.getTime();
    }
  }

  if(isAuth && authExpired) {
    props.dispatch(
      sessionActions.logout()
    );
  }

  // console.log('prefersDarkMode: ', prefersDarkMode);
  const theme = createTheme({
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      
      primary: {
        // Purple and green play nicely together.
        main: prefersDarkMode ? green[500] : green[500],
      },
      secondary: {
        // This is green.A700 as hex.
        main: red[700],
      }
      
    },
  });

  const populateStyle = (theme) => {
    const style = {
      page: {
        minHeight: windowSize.height
      },
      layout:{
        margin: `0 auto`,
        padding: `5.5em 1.0875rem 1.45rem`,
        display: 'flex',
        flexDirection: 'column',
        minHeight: windowSize.height * 0.9
      },
      footer:{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        padding: 6,
        marginTop: `2rem`,
        // Hide the footer when perform printing
        '@media print' : {
          display: 'none'
        },
      },
      footerItem: {
        paddingRight: 10
      },
      footerLink: {
        color: prefersDarkMode ? '#fff' : '#00f'
      },
      authContainer: {
        display: 'flex',
        justifyContent: 'center'
      },
      authBox: {
        display: 'flex',
        flexDirection: 'column',
        width: 600,
        minHeight: 250,
        padding: 10,
        alignItems: 'center'
      },
      row: {
        display: 'flex', 
        flexDirection: 'row',
        gap: 10,
        flexWrap: 'wrap'
      },
      col: {
        display: 'flex', 
        flexDirection: 'column'
      }
    };

    if(Helper.isNotNullAndUndefined(props.backgroundImage)) {
      style.page.backgroundImage = `url(${props.backgroundImage})`
    }

    if(Helper.isNotNullAndUndefined(props.backgroundStyle)) {
      for (const [key, value] of Object.entries(props.backgroundStyle)) {
        style.page[key] = value;
      }
    }

    return style;
  }
  

  const useStyles = makeStyles((theme: Theme) =>
    createStyles(populateStyle(theme))
  );

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  React.useEffect(() => {
    let locale = props.ui.get('lang');
    changeLanguage(locale);

    props.dispatch(
      systemActions.appMenuGetList(locale)
    );

    props.dispatch(
      cartActions.cartGetList()
    );

  }, [props.ui.get('lang')]);
  
  const getPagePath = () => {
    var path = '';
    let locale = props.ui.get('lang');
    
    if(Helper.stringHasValue(window?.location?.href) && Helper.stringHasValue(window?.location?.origin)) {
      path = window.location.href.replace(window.location.origin, '');
      if(Helper.stringHasValue(locale) && locale.toLowerCase() !== 'en') {
        // Not using default language, then remove the language from the path
        path = path.replace(`${locale}/`, '');
      }
    }
    return path;
  }
  
  // Populate the page meta data from menu items if any
  const curPath = getPagePath();
  
  if(!Helper.arrayHasItem(metaData) && Helper.stringHasValue(curPath) && menuItems.length > 0) {
    // If meta data is not pass in via the calling page, then populate it from app menu (if any).
    const curPage = menuItems.find(item => item.route === curPath);
    if(Helper.isNotNullAndUndefined(curPage) &&
       Helper.isNotNullAndUndefined(curPage?.meta_data) &&
       Helper.arrayHasItem(curPage?.meta_data)) {
       curPage.meta_data.map(item => metaData.push(item));
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Seo title={props.title} meta={metaData} />
      <CssBaseline/>
      <Header siteTitle={props.title} />
      <div className={classes.page}>
        <div className={classes.layout}>
          {
            !props.authRequired &&
            <main>{props.children}</main>
          }
          {
            props.authRequired &&
            <main>
              {
                isAuth ?
                props.children
                :
                <div className={classes.authContainer}>
                  <Paper className={classes.authBox}>
                    <h2>{t('layout.authRequire.title')}</h2>
                    <span>{t('layout.authRequire.desc')}</span>
                    <Button variant={'contained'}
                      style={{
                        marginTop: 20
                      }}
                      onClick={() => navigate(Api.authProviderLoginUrl('auth0'))}
                    >
                      {t('layout.authRequire.login')}
                    </Button>
                  </Paper>
                </div>
              }
            </main>
          }
          
        </div>
        <footer className={classes.footer}>
          <div className={classes.row}>
            <div className={classes.footerItem}>
              {`© ${new Date().getFullYear()}, ${t('layout.footer.buildby')}`}
              &nbsp;
              <a target={'_blank'}  className={classes.footerLink} href="https://www.justnice.net">Justnice.Net</a>
            </div>
            <div className={classes.footerItem}>
              <Link className={classes.footerLink} to="/content/?type=TERMS_CONDITIONS">{t('layout.footer.terms')}</Link>
            </div>
            <div className={classes.footerItem}>
              <Link className={classes.footerLink} to="/content/?type=PRIVACY_POLICY">{t('layout.footer.privacy')}</Link>
            </div>
            <div className={classes.footerItem}>
              <Link className={classes.footerLink} to="/aboutus">{t('layout.footer.about')}</Link>
            </div>
            <div className={classes.footerItem}>
              <Link className={classes.footerLink} to="/contact">{t('layout.footer.contact')}</Link>
            </div>
          </div>
        </footer>
      </div>
      <Backdrop className={prefersDarkMode ? commonClasses.backdropDark : commonClasses.backdrop} open={props.ui.get('busy')}>
        <CircularProgress size={50} />
        <div style={{marginTop: 20, fontSize: '1.2em', fontWeight: 'bold'}}>{t('ui.loading')}</div>
      </Backdrop>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  authRequired: PropTypes.bool,
  backgroundImage: PropTypes.any,
  backgroundStyle: PropTypes.object,
  meta: PropTypes.arrayOf(PropTypes.object)
}

Layout.defaultProps = {
  authRequired: true,
  backgroundImage: null,
  backgroundStyle: null,
  meta: []
}

export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    system: state['system']
  };
})(Layout);

