import {actionType} from './actionType';
import Api from '../../lib/api';
import Helper from '@justnice/helper';

export function authenticateCallbackWithProvider(provider, tokens, callback) {
  return function(dispatch) {
    dispatch({type: actionType.session.authPending, payload: null});
    
    Api.authProviderLoginCallback(provider, tokens)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.session.authFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.session.authRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.session.authRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function myProfileGet(callback) {
  return function(dispatch) {
    dispatch({type: actionType.session.myProfileGetPending, payload: null});
    
    Api.myProfileGet()
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.session.myProfileGetFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.session.myProfileGetRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.session.myProfileGetRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function myProfileUpdate(profile, callback) {
  return function(dispatch) {
    dispatch({type: actionType.session.myProfileUpdatePending, payload: null});
    
    Api.myProfileUpdate(profile)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.session.myProfileUpdateFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.session.myProfileUpdateRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.session.myProfileUpdateRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function logout() {
  localStorage.removeItem('jwt');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  return {
    type: actionType.session.logout,
    payload: null
  }
}