import * as React from "react";
import PropTypes from "prop-types";
// import { Link } from "gatsby"；

// import clsx from 'clsx';
// import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';


import Helper from '@justnice/helper';

// import { Menu as MenuIcon,  
//   ChevronLeft as ChevronLeftIcon,
//   ChevronRight as ChevronRightIcon,
//   Inbox as InboxIcon,
//   Mail as MailIcon
// } from '@material-ui/icons';

import * as MUIIcons from '@material-ui/icons';


const Icon = (props) => {
  
  const IconElement = Helper.isNotNullAndUndefined(props.source?.name) && Helper.stringHasValue(props.source?.name) ? MUIIcons[props.source.name] : null;
  if(IconElement)
    return (<IconElement />);
  else {
    if(Helper.isNotNullAndUndefined(props.source?.source)) {
      return <img style={{width: 24, height: 24}} src={props.source.source} />;
    }
    else {
      return null;
    }
  }
}

Icon.propTypes = {
  source: PropTypes.object,
}

Icon.defaultProps = {
  source: null
}


export default Icon;
