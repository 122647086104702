import {actionType} from './actionType';

export function setBusyStatus(busy) {
  return {
    type: actionType.ui.setBusyStatus,
    payload: busy
  }
}

export function setLanguage(lang) {
  return {
    type: actionType.ui.setLanguage,
    payload: lang
  }
}
