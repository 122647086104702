import React, { useLayoutEffect, useState } from 'react';

function useWindowSize() {
  const [size, setSize] = useState({width: 0, height: 0});
  useLayoutEffect(() => {
    function updateSize() {
      setSize({width: window.innerWidth, height: window.innerHeight});
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default useWindowSize;

/*
Usage Example:

function ShowWindowDimensions(props) {
  const size = useWindowSize();
  return <span>Window size: {width} x {height}</span>;
}

*/