import {actionType} from './actionType';
import Api from '../../lib/api';
import Helper from '@justnice/helper';

export function appMenuGetList(locale, callback) {
  return function(dispatch) {
    dispatch({type: actionType.system.appMenuGetListPending, payload: null});
    
    Api.appMenuGetList(locale)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.system.appMenuGetListFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.system.appMenuGetListRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.system.authRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function tagGetList(callback) {
  return function(dispatch) {
    dispatch({type: actionType.system.tagGetListPending, payload: null});
    
    Api.tagList()
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.system.tagGetListFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.system.tagGetListRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.system.tagGetListRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function sysListGet(locale, callback) {
  return function(dispatch) {
    dispatch({type: actionType.system.sysListGetPending, payload: null});
    
    Api.systemListGet(locale)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.system.sysListGetFulfilled,
          payload: {locale, data: result.data}
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.system.sysListGetRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.system.sysListGetRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function contentListGet(locale, callback) {
  return function(dispatch) {
    dispatch({type: actionType.system.contentListGetPending, payload: null});
    
    Api.appContentListGet(locale)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.system.contentListGetFulfilled,
          payload: {locale, data: result.data}
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.system.contentListGetRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.system.contentListGetRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}
